@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');

.App {
  text-align: center;
}

figure {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-size: smaller;
  box-shadow: 0 0 4px gray;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #eeeeee;
}

figcaption {
  margin: 0.5em;
}

figcaption.top {
  text-align: left;
  font-size: smaller;
  margin-bottom: 2em;
}

img.inline {
  max-width: 100%;
  margin: auto;
  vertical-align: top;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page {
  height: 100%;
}

.page-header {
  width: 100%;
  min-height: 2em;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.page-body {
  width: 90%;
  margin-left: 3em;
  margin-left: 3em;
}

.templates-header {
  margin-top: 0.5em;
  display: flex;
  flex-flow: row wrap;
}

.templates-body {
  background-color: rgb(255, 255, 255);
  margin: 0.5em;
  padding: 0.5em;
  position: static;
  top: 5em;
  overflow-y: scroll;
  max-height: 600px;
}

#splash {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: #00796b; */
 /* background-image: url(/webstore/service/v1/masterdata/resources/image/desktop.png);*/
}

/*@media screen and (max-width: 480px) {
  #splash {
    background-image: url(../public/tablet.png)
  }
}

@media screen and (max-width: 480px) {
  #splash {
    background-image: url(../public/mini.png)
  }
}

@media screen and (max-width: 480px) {
  #splash {
    background-image: url(../public/phone.png)
  }
} */

.swiper-slide {
  text-align: center;
  

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination {
  
}

.swiper-horizontal>.swiper-pagination-bullets {
  bottom: -6px !important
}

.swiper-button-prev {
    left: 0px !important
  }
  .swiper-button-next {
    right: 0px !important
  }

  .swiper-xlarge>.swiper-button-prev {
    display: none;
  }
  .swiper-xlarge>.swiper-button-next {
    /* right: 0px !important */
    display: none;
  }

.mobile-navigation {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}

